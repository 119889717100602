import { useState, useCallback } from 'react';

interface UseModalResult {
    showModal: boolean;
    handleOpenModal: () => void;
    handleCloseModal: () => void;
}

const useModal = (): UseModalResult => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = useCallback(() => {
        setShowModal(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);

    return {
        showModal,
        handleOpenModal,
        handleCloseModal,
    };
};

export default useModal;
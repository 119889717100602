import ChatFrame from "./ChatFrame/ChatFrame";
import style from "./ChatPage.module.scss"
import ChatBar from "./ChatBar/ChatBar";

const ChatPage=()=>{
    return (
        <div className={style.chatPage}>
            <ChatBar/>
            <ChatFrame/>
        </div>
    )
}

export default ChatPage
import {AxiosRequestConfig} from "axios";
import ask from "@/common/api/base";
import {baseResponse} from "@/common/api/common";

interface INoteResponse {
    noteID: number,
    message: string
}

interface INotesResponse {
    notes: INote[];
    message: string
}

export interface INote {
    title: string,
    content: string,
    noteID?: number
}

export const NoteApi = {
    async addNote(note: INote, config?: AxiosRequestConfig) {
        return await ask.post<INoteResponse>('/go/v0/Note', note, config);
    },
    async getNotes(config?: AxiosRequestConfig) {
        return await ask.get<INotesResponse>('/go/v0/Notes', config);
    },
    async deleteNote(noteID: number, config?: AxiosRequestConfig) {
        return await ask.delete<baseResponse>(`/go/v0/Note/${noteID}`, config)
    },

    async updateNote(noteID: number, note: INote, config?: AxiosRequestConfig) {
        return await ask.put<INoteResponse>(`/go/v0/Note/${noteID}`, note, config)
    }
}
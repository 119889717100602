import {createAsyncThunk} from "@reduxjs/toolkit";
import {NoteState} from "@/store/noteStore/noteSlice";
import Ask from "@/common/api/api";
import {NoteHistoryState} from "@/store/noteStore/noteHistorySlice";


export const getNoteHistoriesAsync = createAsyncThunk(
    'note/getHistories',
    async (conversationData, thunkAPI) => {
        try {
            const res=await Ask.getNotes()
            const data:NoteHistoryState= res.data;
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
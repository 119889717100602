import style from "./MessageSender.module.scss"
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {v4} from 'uuid'
import {RootState} from "@/store/store";
import useSocketListeners from "@/component/ChatPage/MessageSender/socketListeners";
import ConfigSetter from "@/component/ConfigSetter/ConfigSetter";
import {clearMessage, MessageState} from "@/store/messageSlice";
import {resetConversation} from "@/store/conversationSlice";
import {addMessageAsync} from "@/store/thunks/message";
import SocketManager from "@/model/sio";
import Ask, {IAIMessage} from "@/common/api";


type MessageSenderProps={
    onSend?:()=>void
}
const MessageSender:React.FC<MessageSenderProps>=props=>{
    // const [messages, setMessage] = useState(["hello"]);
    const messageState = useSelector((state: RootState) => state.message);
    const configState = useSelector((state: RootState) => state.config);
    const conversationState = useSelector((state: RootState) => state.conversation);
    const dispatch = useDispatch()
    const [value, setValue] = useState("");

    useSocketListeners();

    return (<div className={style.messageSender}>
        <ConfigSetter/>
        <form onSubmit={handleSubmit}>


            <div className={style.container}>
                <button type="button" onClick={() => {
                    dispatch(clearMessage())
                    dispatch(resetConversation())
                }}>Clear</button>
                <textarea id="myInput" name="myInput" placeholder="请输入文本" value={value}
                          className={style.textArea} rows={5}
                          onChange={(e) => setValue(e.target.value)}
                          onKeyDown={handleKeyDown}
                />
                <button type="submit">Submit</button>
            </div>

        </form>
        {/*<button onClick={() => dispatch(clearMessage())}>Clear</button>*/}
    </div>)

    function handleSubmit(event: React.FormEvent<HTMLFormElement> | null) {
        if (event)
            event.preventDefault();
        // submitWithBackend(value).then(r => {
        //     props.onSend?.();  //执行外界调用
        //     setValue("");
        // });
        submitInput(value);
        props.onSend?.();  //执行外界调用
        setValue("");
    }

    async function submitWithBackend(message:string) {
        if(conversationState.conversationID===undefined){
            submitInput(message)
            return
        }

        const aiMessage:IAIMessage={content:message,role:"user"};
        const res=await Ask.addMessage(conversationState.conversationID,aiMessage);
        if(res.status===200){
            submitInput(message)
        }else {
            console.error("submit message failed")
        }

    }

    function submitInput(message: string) {
        const messageList = [...messageState];
        if (message !== "") {
            const ms: MessageState = {role: "user", content: message, uid: v4()};
            messageList.push(ms);
            dispatch(addMessageAsync(ms) as any);
        }

        // 这里去除uid
        const cleanList = messageList.map(item => {
            return {role: item.role, content: item.content};
        });

        const sendingData = {
            model: configState.model,
            uid: v4(),
            context: cleanList,
            token: configState.token,
        };
        SocketManager.emit('sendingData', JSON.stringify(sendingData));
        console.log(sendingData);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === 'Enter' && !e.shiftKey && !e.nativeEvent.isComposing) {
            // 如果按下的是Enter键且没有按下Shift键，则提交表单
            e.preventDefault(); // 阻止默认的换行行为
            handleSubmit(null);
        }
    };
}

export default MessageSender;
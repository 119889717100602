// features/counter/counterSlice.ts
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {addConversationAsync, deleteConversationAsync, getAllConversationsAsync} from "./thunks/conversation";
import {addMessageAsync, deleteMessageAsync} from "./thunks/message";
import {MessageState} from "./messageSlice";
import {ConversationState} from "./conversationSlice";
import {IAIConversation} from "@/common/api";

// 定义 state 的类型
export interface ChatHistoryState {
    conversations?: IAIConversation[];
    loading:boolean,
    error:any,
}

// 初始化 state
const initialState: ChatHistoryState = {
    conversations: [],
    loading:false,
    error:null,
};

// 使用 createSlice 创建 slice
export const chatHistorySlice = createSlice({
    name: 'chatHistory',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            //fulfilled pending reject 三种状态
            .addCase(getAllConversationsAsync.fulfilled, (state:ChatHistoryState, action:PayloadAction<IAIConversation[]>) => {
                const newState:ChatHistoryState = {
                    conversations: action.payload,
                    loading: false,
                    error: null,
                }
                return newState;
            })
            .addCase(addConversationAsync.fulfilled, (state:ChatHistoryState, action:PayloadAction<IAIConversation>) => {
                state.conversations?.push(action.payload);
            })
            .addCase(addMessageAsync.fulfilled, (state:ChatHistoryState, action) => {
                const conversationID = action.payload.conversationID;
                if(!state.conversations || !conversationID){
                    return
                }
                const currentConversation = state.conversations.find(
                    conversation => conversation.conversationID === conversationID
                );

                if (currentConversation) {
                    currentConversation.AIMessages.push(action.payload);
                }
            })
            .addCase(deleteMessageAsync.fulfilled, (state:ChatHistoryState, action:PayloadAction<number>) => {
                if(!state.conversations){
                    return
                }
                state.conversations.forEach(conversation => {
                    conversation.AIMessages = conversation.AIMessages.filter(
                        message => message.messageID !== action.payload
                    );
                });
            })
            .addCase(deleteConversationAsync.fulfilled, (state:ChatHistoryState, action:PayloadAction<number>) => {
                if(!state.conversations){
                    return
                }
                state.conversations = state.conversations.filter(
                    conversation => conversation.conversationID !== action.payload
                );
            })
    },

});

// 导出 actions
export const {  } = chatHistorySlice.actions;

// 导出 reducer
export default chatHistorySlice.reducer;

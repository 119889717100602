import ask from "./base";
import {AxiosRequestConfig} from "axios";
import {ConversationsApi} from "@/common/api/Conversations";
import {BaseApi, baseResponse} from "@/common/api/common";
import {NoteApi} from "@/common/api/note";
import {CommandApi} from "@/common/api/commands";
import {MessageBoardApi} from "@/common/api/messageBoard";

const Ask = {
    ...BaseApi,
    ...ConversationsApi,
    ...NoteApi,
    ...CommandApi,
    ...MessageBoardApi,
};

export default Ask;
import {createAsyncThunk} from "@reduxjs/toolkit";
import Ask, {INote} from "@/common/api";

export const addNoteAsync = createAsyncThunk(
    'note/addNote',
    async (note: INote, thunkAPI) => {
        try {
            const res = await Ask.addNote(note)
            const data: INote = {title: note.title, content: note.content, noteID: res.data.noteID};
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const updateNoteAsync = createAsyncThunk(
    'note/updateNote',
    async (note: INote, thunkAPI) => {
        try {
            const res = await Ask.updateNote(note.noteID!, note);
            return note;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteNoteAsync = createAsyncThunk(
    'note/deleteNote',
    async (noteID: number, thunkAPI) => {
        try {
            await Ask.deleteNote(noteID);
            return noteID;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {addNoteAsync, deleteNoteAsync, updateNoteAsync} from "@/store/noteStore/thunks/note";

export interface NoteState{
    title:string,
    content: string;
    noteID?:number
}
const initialState: NoteState = {
    title: "",
    content: "",
    noteID: undefined
};

export const NoteSlice=createSlice(
    {
        name: "note",
        initialState,
        reducers: {
            setNote(state, action: PayloadAction<NoteState>) {
                state.title = action.payload.title;
                state.content = action.payload.content;
                state.noteID = action.payload.noteID;
            }
        },
        extraReducers:(builder)=>{
            builder
                .addCase(addNoteAsync.fulfilled,(state,action:PayloadAction<NoteState>)=>{
                    state.title = action.payload.title;
                    state.content = action.payload.content;
                    state.noteID = action.payload.noteID;
                })
                .addCase(updateNoteAsync.fulfilled,(state,action:PayloadAction<NoteState>)=>{
                    state.title = action.payload.title;
                    state.content = action.payload.content;
                    state.noteID = action.payload.noteID;
                })
                .addCase(deleteNoteAsync.fulfilled,(state,action:PayloadAction<number>)=>{
                    state.title = "";
                    state.content = "";
                    state.noteID = undefined;
                })
        }
    }
)
export const {
    setNote
}=NoteSlice.actions
const noteReducer=NoteSlice.reducer;
export default noteReducer;
import {createAsyncThunk} from "@reduxjs/toolkit";
import Ask, {INote} from "@/common/api";

export const getMessageBoardAsync = createAsyncThunk(
    'index/getMessageBoard',
    async (jwt: string, thunkAPI) => {
        try {
            return "value";
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const updateMessageBoardAsync = createAsyncThunk(
    'index/updateMessageBoard',
    async (value: string, thunkAPI) => {
        try {

        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
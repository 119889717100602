import {Checkbox} from "@material-ui/core";
import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {changeModel, changeToken} from "../../store/configSlice";

const ConfigSetter: React.FC = () => {

    return (
        <div>
            <TokenNum/>
            <ModelSelector/>
        </div>
    )
}

export default ConfigSetter;


const options = ['a', 'b', 'c'];

function TokenNum() {
    const config = useSelector((state: RootState) => state.config);
    const dispatch = useDispatch();
    return (<>
            <label htmlFor="token">Token:</label>
            <input id="token" type="number" value={config.token} onChange={handleChange}/>
        </>
    )

    function handleChange(event:React.ChangeEvent<HTMLInputElement>) {
        const value = parseInt(event.target.value)
        if (isNaN(value)) {
            return
        }
        dispatch(changeToken(value));
    }
}

function ModelSelector() {
    const configState=useSelector((state:RootState)=>state.config);
    const dispatch=useDispatch();
    const [selectedModel, setSelectedModel] = useState(configState.model);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const models = configState.models;
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    }
    const selectModel = (model: string) => {
        setSelectedModel(model);
        dispatch(changeModel(model))
        toggleDropdown();
    }
    return (<div>
        <button onClick={toggleDropdown}> {selectedModel || "Select a Model"} {isDropdownOpen ? '^' : 'v'} </button>
        {isDropdownOpen &&
            <div> {models.map(model => <div key={model} onClick={() => selectModel(model)}> {model} </div>)} </div>}
    </div>);
}
import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import style from './ChatFrame.module.scss';
import Cookies from "js-cookie";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RootState} from "@/store/store";
import useInputModal from "@/component/common/InputModal/useInputModal";
import ChatBubble from "@/component/ChatPage/ChatBubble/ChatBubble";
import debugConfig from "@/debugConfig";
import MessageSender from "@/component/ChatPage/MessageSender/MessageSender";
import {addMessage} from "@/store/messageSlice";
import ask from "@/common/api/base";
import InputModal from "@/component/common/InputModal/InputModal";


const ms_exam = {
    "role": "assistant",
    "content": "等腰三角形的面积公式为 $A = \\frac{1}{2} \\times b \\times h$，其中 $b$ 为底边长，$h$ 为高度。" +
        "你可以使用JavaScript来编写一个简单的 `Hello, World!` 程序。以下是一个示例：\n\n```javascript\n// 在浏览器的控制台中输出 Hello, World!\nconsole.log(\"Hello, World!\");\n```\n\n你可以将上述代码复制粘贴到一个 .html 文件中，然后在浏览器中打开该文件，或者直接在浏览器的开发者工具中的控制台中运行这段代码。这样就可以看到显示 Hello, World! 的输出了。"
        + "当然，请看以下数学公式示例：\n" +
        "\n" +
        "6. 二项式定理\n" +
        "    - $(a + b)^n = \\sum_{k=0}^{n} \\binom{n}{k} a^{n-k} b^{k}$\n" +
        "\n" +
        "7. 微积分中的导数公式\n" +
        "    - $\\frac{dy}{dx} = \\lim_{h \\to 0} \\frac{f(x+h) - f(x)}{h}$\n" +
        "\n" +
        "8. 平面几何中的中点公式\n" +
        "    - $M\\left(\\frac{x_1 + x_2}{2}, \\frac{y_1 + y_2}{2}\\right)$\n" +
        "\n" +
        "9. 高中数学中的三角函数关系式\n" +
        "    - $\\sin^2 \\theta + \\cos^2 \\theta = 1$\n" +
        "\n" +
        "10. 指数函数的导数公式\n" +
        "    - $\\frac{d}{dx} e^x = e^x$\n" +
        "\n" +
        "希望这些公式能够继续帮助测试排版效果。",
    "uid": "1"
};

const ChatFrame: React.FC = () => {
    const messages = useSelector((state: RootState) => state.message);
    const dispatch = useDispatch();
    const endOfMessagesRef = useRef<null | HTMLDivElement>(null); // 用于自动滚动的ref
    const messagesContainerRef = useRef<null | HTMLDivElement>(null); // 用于监听滚动事件的ref
    const allowScroll = useRef(false);
    const lastScrollTop = useRef(0); // 用于记录上一次滚动位置

    // 自动滚动到消息底部的逻辑
    useEffect(() => {
        if (allowScroll.current && endOfMessagesRef.current) {
            // 使用平滑滚动滚动到div的底部
            setTimeout(() => {
                endOfMessagesRef.current!.scrollIntoView({behavior: "smooth"});
            }, 100);
        }
    }, [messages]);

    //用户是否向上滚动
    useEffect(() => {
        const handleUserScroll = () => {
            const currentScrollTop = messagesContainerRef.current!.scrollTop;

            // 检测是否向上滚动
            if (currentScrollTop < lastScrollTop.current) {
                // 向上滚动
                allowScroll.current = false;
            } else {
                // 向下滚动或未滚动时，可以选择是否需要更新状态
            }

            // 更新上一次滚动位置
            lastScrollTop.current = currentScrollTop;
        };

        const messagesContainer = messagesContainerRef.current;
        messagesContainer?.addEventListener('scroll', handleUserScroll);

        return () => {
            messagesContainer?.removeEventListener('scroll', handleUserScroll);
        };
    }, []); // 依赖数组为空，这意味着此useEffect会在组件挂载时运行一次

    const { showModal, handleOpenModal, handleCloseModal } = useInputModal();
    const handleConfirm = (input: string) => {
        console.log('User Input:', input);
        handleCloseModal();
    };


    return (
        <div className={style.chatFrame}>
            <ToastContainer autoClose={700}/>
            <div className={style.messagesContainer} ref={messagesContainerRef}>
                {messages.map((msg, index) => <ChatBubble key={index} {...msg} />)}
                {/*标记滚动位置 */}
                <div ref={endOfMessagesRef}></div>
            </div>
            {
                debugConfig.isDebug ? (
                    debugComponent()
                ) : null
            }
            <MessageSender onSend={() => {
                allowScroll.current = true
            }}/>
        </div>
    );

    function toastInfo(info: string) {
        let val = Cookies.get('test');
        console.log(val);
        toast(info, {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function debugComponent(){
        return (
            <div>
                <button onClick={() => {
                    allowScroll.current = true
                    dispatch(addMessage(ms_exam))
                }}>add
                </button>
                <button onClick={() => {
                    console.log(allowScroll)
                }}>isAllowScroll
                </button>
                <button onClick={() => {
                    ask.get('/go/v0/ping').then((res:any) => {
                        console.log(res)
                    })
                }}>ping
                </button>
                <button onClick={() => {
                    ask.get('/go/v0/pingJWT').then((res:any) => {
                        console.log(res)
                    })
                }}>pingJWT
                </button>
                <button onClick={()=>{
                    ask.post('/go/v0/addConversation',{title:"hello"})
                }}>
                    add Conversation
                </button>
                <button onClick={handleOpenModal}>Open Modal</button>
                <InputModal isShowModal={showModal} title="Please enter something" onClose={handleCloseModal} onConfirm={handleConfirm} />
                <button onClick={async ()=>{
                    const res=await ask.post('/go/v0/addAIConversation',
                        {
                            "conversation": {
                                "title": "test Conversation Title"
                            },
                            "messages": [
                                {
                                    "content": "Hello, how are you?",
                                    "role": "user",
                                },
                                {
                                    "content": "I'm good, thanks! And you?",
                                    "role": "assistant",
                                }
                            ]
                        });
                    console.log(res)

                }}>addAIConversation</button>
            </div>
        )
    }
}

export default ChatFrame;
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styles from './CommandPage.module.scss';
import {addCommandAsync, searchCommandAsync} from "@/store/commandStore/thunks/command";
import {RootState} from "@/store/store";
import CommandComponent from "@/component/CommandPage/CommandComponent";
import CustomModal from "@/component/common/CustomModal/CustomModal";
import {ICommand} from "@/common/api";
import {FaPlus} from "react-icons/fa";

const CommandPage = () => {
    const [search, setSearch] = useState("");
    const commands = useSelector((state: RootState) => state.command.commands);
    const dispatch = useDispatch();
    const [isShowModal, setIsShowModal] = useState(false)

    useEffect(() => {
        dispatch(searchCommandAsync(search) as any);
    }, [search]);

    function onCloseModal() {
        setIsShowModal(false)
    }

    function onConfirm(formData: { [key: string]: any }) {
        const newCommand: ICommand = {
            category: formData.category,
            command: formData.command,
            description: formData.description
        }
        dispatch(addCommandAsync(newCommand) as any)
        setIsShowModal(false)
    }

    return (
        <div className={styles.pageContainer}>
            <CustomModal isShowModal={isShowModal} onCancel={onCloseModal} onConfirm={onConfirm}>
                {addCommandComponent()}
            </CustomModal>
            <h1>Command Page</h1>
            <div className={styles.searchContainer}>
                <input
                    className={styles.searchInput}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                <button className={styles.addButton} onClick={() => setIsShowModal(true)}>
                    <FaPlus/>
                </button>
            </div>
            <div className={styles.commandsContainer}>
                {commands?.map((command, index) => (
                    <CommandComponent command={command} index={index}/>
                ))}
            </div>
        </div>
    );
};


const addCommandComponent = () => {
    return (
        <>
            <div>
                <label htmlFor="category">Category</label>
                <input id="category" name="category" type="text"/>
            </div>
            <div>
                <label htmlFor="description">Description</label>
                <input id="description" name="description" type="text"/>
            </div>
            <div>
                <label htmlFor="command">Command</label>
                <input id="command" name="command" type="text"/>
            </div>
        </>
    )
}
export default CommandPage;

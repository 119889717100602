import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@/store/store";
import {useEffect} from "react";
import {getNoteHistoriesAsync} from "@/store/noteStore/thunks/noteHistory";
import {setNote} from "@/store/noteStore/noteSlice";

const NoteHistory=()=>{
    const noteHistoriesState=useSelector((root:RootState)=>root.noteHistory)
    const dispatch=useDispatch();
    useEffect(() => {
        dispatch(getNoteHistoriesAsync() as any)
    }, []);
    return (
        <div>
            <h1>note history</h1>
            {noteHistoriesState.notes?.map((note,index)=>{
                return (
                    <div onClick={()=>dispatch(setNote(note))} key={index}>
                        {note.title||"untitled"}
                    </div>
                );
            })}
        </div>
    )
}

export default NoteHistory
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Button} from "@material-ui/core";
import style from "@/component/ChatPage/ChatBubble/ChatBubble.module.scss";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {dark} from "react-syntax-highlighter/dist/esm/styles/prism";

const renderers = {
    code: (props: any) => {
        if (!props.className) {
            // 没有语言类型,不另起一行，也不添加复制按钮
            return <span style={{color: '#1E90FF', fontWeight: 'bold'}}>{props.children}</span>
        }
        const language = props.className;
        const value = props.children;
        return (<div style={{position: 'relative'}}>
            <CopyToClipboard text={value}>
                <Button variant="contained" color="primary"
                        className={style.TRButton}
                        size={'small'}
                        children={"copy"}/>
            </CopyToClipboard>
            <SyntaxHighlighter style={dark} language={language} children={value}/>
        </div>);
    },
};

interface IProps {
    content: string
}

export const SimpleMarkdown: React.FC<IProps> = props => {
    return (<ReactMarkdown children={props.content}
                           components={renderers}
                           remarkPlugins={[remarkMath]}
                           rehypePlugins={[rehypeKatex, rehypeRaw]}/>)
}
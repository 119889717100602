import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@/store/store";
import Ask from "@/common/api/api";
import {loginAndSetup} from "@/store/thunks/login";
import Cookies from "js-cookie";

// Assume all necessary imports here (Cookies, useNavigate, useSelector, etc.)

function LoginComponent() {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const params = new URLSearchParams(location.search);
    const from = params.get("from") || "/";
    const userState = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch()

    useEffect(() => {
        const token = Cookies.get('jwtToken') as string;
        dispatch(loginAndSetup({jwtToken: token}) as any);
    }, []);
    useEffect(() => {
        if (userState.isLogged) {
            console.log(from)
            navigate(from, {replace: true});
            setLoading(false);
        }
    }, [userState.isLogged, navigate, from]);

    return (
        <div>
            <p>You must log in to view the page at {from}</p>
            <form onSubmit={handleSubmit}>
                <input type="hidden" name="redirectTo" value={from}/>
                <label>
                    Username: <input name="username"/>
                </label>
                <label>
                    Password: <input type="password" name="password"/>
                </label>
                <button type="submit" disabled={loading}>
                    {loading ? "Logging in..." : "Login"}
                </button>
                {error && (
                    <p style={{color: "red"}}>{error}</p>
                )}
            </form>
        </div>
    );

    async function handleSubmit(event:React.FormEvent<HTMLFormElement>){
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const username = formData.get("username") as string;
        const password = formData.get("password") as string;
        try {
            if (username && password) {
                const res = await Ask.login(username, password);
                const token = res.data.token;
                dispatch(loginAndSetup({jwtToken: token}) as any);
                setLoading(true);
            } else {
                setError('username or password empty');
            }
        } catch (e: any) {
            if (e.response?.data?.message)
                setError(e.response.data.message)
            else {
                setError("login error")
            }
        }
    };
}

export default LoginComponent;
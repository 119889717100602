import {AxiosRequestConfig} from "axios";
import ask from "@/common/api/base";
import {baseResponse} from "@/common/api/common";

export interface IAIMessage {
    content: string;
    role: string;
    conversationID?: number;
    messageID?: number;
}

export interface IAIConversation {
    title :string;
    conversationID?: number;
    AIMessages: IAIMessage[];
}

interface IGetAIConversation{
    conversation:IAIConversation,
    message:string,
}

interface IGetAIConversations {
    conversations: IAIConversation[];
    message: string;
}

interface IAddAIConversationResponse {
    conversationID: number;
    message: string;
}

interface IAddAIMessageResponse {
    message: string;
    messageID: number;
}

export const ConversationsApi={
    async addAIConversation(conversation: IAIConversation, config?: AxiosRequestConfig) {
        return await ask.post<IAddAIConversationResponse>('/go/v0/AIConversation', conversation, config);
    },

    async getAIConversation(conversationID: number, config?: AxiosRequestConfig) {
        return await ask.get<IGetAIConversation>(`/go/v0/AIConversation/${conversationID}`, config);
    },

    async getAIConversations(config?: AxiosRequestConfig) {
        return await ask.get<IGetAIConversations>('/go/v0/AIConversations', config);
    },

    async addMessage(conversationID: number, message: IAIMessage, config?: AxiosRequestConfig) {
        return await ask.post<IAddAIMessageResponse>
        ('/go/v0/AIMessage', { ...message, conversationID: conversationID}, config);
    },

    async deleteMessage(messageID: number, config?: AxiosRequestConfig) {
        return await ask.delete<baseResponse>(`/go/v0/AIMessage/${messageID}`, config);
    },

    async deleteAIConversation(conversationID: number, config?: AxiosRequestConfig) {
        return await ask.delete<baseResponse>(`/go/v0/AIConversation/${conversationID}`, config);
    },
}
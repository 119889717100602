import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICommand, IGetCommandResponse} from "@/common/api";
import {
    addCommandAsync,
    deleteCommandAsync,
    searchCommandAsync,
    updateCommandAsync
} from "@/store/commandStore/thunks/command";

export interface CommandsState {
    commands: ICommand[],
}

const initialState: CommandsState = {
    commands: []
};

export const CommandSlice = createSlice(
    {
        name: "note",
        initialState,
        reducers: {
            setCommand: (state: CommandsState, action: PayloadAction<ICommand>) => {
                const index = state.commands.findIndex(command => command.commandID === action.payload.commandID);
                if (index !== -1) {
                    state.commands[index] = action.payload;
                }
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(searchCommandAsync.fulfilled, (state: CommandsState, action: PayloadAction<IGetCommandResponse>) => {
                    state.commands = action.payload.commands;
                })
                .addCase(updateCommandAsync.fulfilled, (state: CommandsState, action: PayloadAction<ICommand>) => {
                    state.commands =
                        state.commands.map(command =>
                            command.commandID === action.payload.commandID ? action.payload : command)
                })
                .addCase(deleteCommandAsync.fulfilled, (state: CommandsState, action: PayloadAction<string>) => {
                    state.commands = state.commands.filter(command => command.commandID !== action.payload);
                })
                .addCase(addCommandAsync.fulfilled, (state: CommandsState, action: PayloadAction<ICommand>) => {
                    state.commands.push(action.payload);
                })
        }
    }
)
export const {
    setCommand
} = CommandSlice.actions
const commandReducer = CommandSlice.reducer;
export default commandReducer;
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IAIConversation} from "@/common/api";
import {v4} from 'uuid'
import {addMessageAsync, deleteMessageAsync} from "./thunks/message";

// 定义 state 的类型

export interface MessageState {
    role: string,
    content: string,
    uid: string,
    messageID?: number,
}

// 初始化 state
const initialState: MessageState[] = [];

// 使用 createSlice 创建 slice
export const MessageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        addMessage: (state, action: PayloadAction<MessageState>) => {
            state.push(action.payload);
        },
        updateMessage: (state, action: PayloadAction<MessageState>) => {
            const {payload} = action;
            const index = state.findIndex(msg => msg.uid === payload.uid);

            if (index === -1) {
                return [...state, payload];
            } else {
                return state.map((msg, i) => {
                    if (i === index) {
                        return payload;
                    } else {
                        return msg;
                    }
                });
            }
        },
        deleteMessageByUid: (state, action: PayloadAction<string>) => {
            const index = state.findIndex(msg => msg.uid === action.payload);

            if (index !== -1) {
                state.splice(index, 1);
            }
        },
        // 使用 PayloadAction 来指定 action.payload 的类型
        clearMessage: (state) => {
            state.splice(0);
        },
        setMessage: (state, action: PayloadAction<IAIConversation>) => {
            state.splice(0)
            action.payload.AIMessages?.map((message) => {
                state.push({...message, uid: v4()})
            })
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addMessageAsync.fulfilled, (state, action) => {
                state.push(action.payload);
            })
            .addCase(deleteMessageAsync.fulfilled, (state, action) => {
                const index = state.findIndex(msg => msg.messageID === action.payload);
                if (index !== -1) {
                    state.splice(index, 1);
                }
            })

    }
});

// 导出 actions
export const
    {
        addMessage,
        updateMessage,
        deleteMessageByUid,
        clearMessage,
        setMessage
    } = MessageSlice.actions;

// 导出 reducer
export default MessageSlice.reducer;

import React, {useState, useEffect, useRef} from 'react';
import styles from './CustomModal.module.scss';

interface IProps {
    isShowModal: boolean;
    title?: string;
    children: React.ReactNode;
    onCancel: () => void;
    onConfirm: (formData: { [key: string]: any }) => void;
}

const CustomModal: React.FC<IProps> = props => {
    const {isShowModal, children, onCancel, onConfirm, title} = props;
    const inputRef = useRef<HTMLInputElement>(null); // 使用 useRef 获取 input 的引用
    const [formData, setFormData] = useState({});

    useEffect(() => {
        setFormData({}); // 每次弹窗显示时重置输入内容
        if (isShowModal) {
            // 当模态框显示时，聚焦到输入框
            inputRef.current?.focus();
        }
    }, [isShowModal]);

    useEffect(() => {
        // 处理键盘事件
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                handleSubmit();
            } else if (event.key === 'Escape') {
                onCancel();
            }
        };

        if (isShowModal) {
            // 组件挂载时添加事件监听
            document.addEventListener('keydown', handleKeyDown);
        }

        // 组件卸载时移除事件监听
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onConfirm, onCancel, isShowModal]); // 依赖数组中包括了 input, onConfirm, onClose 以及 show

    if (!isShowModal) return null;

    const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((event.target as HTMLElement).id === "modal-backdrop") {
            onCancel();
        }
    };

    const handleInputChange = (e:  React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = () => {
        onConfirm(formData);
    };


    return (
        <div id="modal-backdrop" onClick={handleBackdropClick} className={styles.modalBackdrop}>
            <div className={styles.modal}>
                {title && <div className={styles.title}>{title}</div>}
                <div className={styles.content} onChange={handleInputChange}>
                    {children}
                </div>
                <div className={styles.footer}>
                    <button onClick={onCancel}>Cancel</button>
                    <button onClick={handleSubmit}>Confirm</button>
                </div>
            </div>
        </div>
    );
};


export default CustomModal;
import {createAsyncThunk} from "@reduxjs/toolkit";
import Ask from "../../common/api/api";
import Cookies from "js-cookie";
import {HttpStatusCode} from "axios";

interface ILogin{
    jwtToken:string
}
interface RLogin{
    jwtToken:string,
    userName:string,
}
const loginAndSetup=createAsyncThunk(
  "auth/loginAndSetup",
  async (payload:ILogin,thunkAPI) => {
        if(payload.jwtToken){
            Cookies.set("jwtToken",payload.jwtToken);
        }
      const response =await Ask.jwtLogin();
      if(response.status!==200){
          console.error(response);
          return  thunkAPI.rejectWithValue(response.status);
      }
      Cookies.set("jwtToken",response.data.token);
      const res:RLogin={
          jwtToken:response.data.token,
          userName:response.data.username,
      }
      return res;
  }
);

export {loginAndSetup}
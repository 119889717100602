import React, {useEffect} from 'react';
import {Button} from '@material-ui/core';
import {useDispatch} from "react-redux";
import style from "./ChatBubble.module.scss"
import {deleteMessageByUid, MessageState} from "@/store/messageSlice";
import {deleteMessageAsync} from "@/store/thunks/message";
import {SimpleMarkdown} from "@/component/common/SimpleMarkdown/SimpleMarkdown";


export default function ChatBubble(message: MessageState) {
    useEffect(() => {
    }, []);
    const dispatch = useDispatch();
    // 根据message.role动态分配类名
    const bubbleClass = message.role === 'assistant' ? `${style.chatBubble} ${style.assistantBubble}` : `${style.chatBubble} ${style.userBubble}`;

    return (
        <div className={bubbleClass}>
            <Button variant={"contained"} size="small" className={style.TRButton}
                    onClick={handleClick}>
                delete
            </Button>
            <SimpleMarkdown content={`# ${message.role}\n\n${message.content}`}/>
        </div>
    );

    function handleClick() {
        if (message.messageID) {
            dispatch(deleteMessageAsync(message.messageID) as any);
        } else
            dispatch(deleteMessageByUid(message.uid))
    }
}
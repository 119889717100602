// features/counter/counterSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// 定义 state 的类型
interface ConfigState {
    model: string;
    models:string[];
    token:number;
}

// 初始化 state
const initialState: ConfigState = {
    model: 'gpt-3.5-turbo-0125',
    models:['gpt-3.5-turbo-0125','gpt-4-0125-preview','claude-3-sonnet-20240229','claude-3-opus-20240229'],
    token: 2000
};

// 使用 createSlice 创建 slice
export const configSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        changeToken: (state,action:PayloadAction<number>) => {
            state.token=action.payload;
        },
        changeModel: (state,action:PayloadAction<string>) => {
            const model=action.payload;
            if(state.models.includes(model)){
                state.model=model;
            }
        },
        // 使用 PayloadAction 来指定 action.payload 的类型
        incrementedByAmount: (state, action: PayloadAction<number>) => {
            // state.value += action.payload;
        },
    },
});

// 导出 actions
export const { changeToken, changeModel, incrementedByAmount } = configSlice.actions;

// 导出 reducer
export default configSlice.reducer;

import React, {useEffect, useState} from 'react';
import {SimpleMarkdown} from "@/component/common/SimpleMarkdown/SimpleMarkdown";
import styles from './NoteComponent.module.scss'
import InputModal from "@/component/common/InputModal/InputModal";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@/store/store";
import {addNoteAsync, deleteNoteAsync, updateNoteAsync} from "@/store/noteStore/thunks/note";
import {NoteState, setNote} from "@/store/noteStore/noteSlice";
import EditableTitle from "@/component/common/EditableTitle/EditableTitle";

const NoteComponent = () => {
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const note = useSelector((root: RootState) => root.note)
    const [isTextareaVisible, setTextareaVisible] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        setContent(note.content)
        setTitle(note.title)
    }, [note]);
    return (
        <div className={styles.noteComponent}>
            <EditableTitle title={title} setTitle={setTitle}/>
            {/*<button*/}
            {/*    className={styles.toggleButton}*/}
            {/*    onClick={() => setTextareaVisible(!isTextareaVisible)}*/}
            {/*>*/}
            {/*    {isTextareaVisible ? 'Hide' : 'Show'} Input*/}
            {/*</button>*/}
            <div className={styles.noteContainer}>
                {isTextareaVisible && (
                    <textarea
                        className={styles.noteInput}
                        value={content}
                        onChange={e => {
                            setContent(e.target.value)
                        }}
                    />
                )}
                {/*<div className={styles.notePreview}>*/}
                {/*    <SimpleMarkdown content={content}/>*/}
                {/*</div>*/}
                {!note.noteID && <button className={styles.saveButton} onClick={handleSave}>save</button>}
                {note.noteID && <div>
                    <button onClick={handleDelete}>delete</button>
                    <button onClick={handleUpdate}>update</button>
                    <button onClick={handleNew}>new</button>

                </div>}
            </div>
        </div>
    )

    function handleSave() {
        const note: NoteState = {
            title: title,
            content: content
        }
        dispatch(addNoteAsync(note) as any)
    }

    function handleDelete() {
        if (!note.noteID) return;
        dispatch(deleteNoteAsync(note.noteID) as any);
    }

    function handleNew() {
        setContent("");
        const newNode: NoteState = {
            title: "",
            content: "",
            noteID: undefined
        }
        dispatch(setNote(newNode));
    }

    function handleUpdate() {
        if (!note.noteID) return;
        const newNote: NoteState = {
            title: title,
            content: content,
            noteID: note.noteID
        }
        // console.log("update  " + note.content)
        dispatch(updateNoteAsync(newNote) as any);
    }
}

export default NoteComponent;
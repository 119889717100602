import React, { useState } from 'react';
import styles from './EditableTitle.module.scss'; // 假设你的CSS文件名为EditableTitle.css
interface IProps{
    title:string
    setTitle:(title:string)=>void
}
const EditableTitle: React.FC<IProps> = props => {
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const title=props.title;
    const handleDoubleClick = () => {
        setIsEditable(true);
    };

    const handleBlur = () => {
        setIsEditable(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setTitle(e.target.value);
    };

    return (
        <div>
            {isEditable ? (
                <input
                    type="text"
                    value={title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                    className={styles.editableInput} // 你可以在CSS中定义这个类
                    placeholder={"empty title"}
                />
            ) : (
                <div className={styles.editableTitle} onDoubleClick={handleDoubleClick}>
                    {title??"empty title"}
                </div>
            )}
        </div>
    );
};

export default EditableTitle;

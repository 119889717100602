import {NoteState} from "@/store/noteStore/noteSlice";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getNoteHistoriesAsync} from "@/store/noteStore/thunks/noteHistory";
import {addNoteAsync, deleteNoteAsync, updateNoteAsync} from "@/store/noteStore/thunks/note";

export interface NoteHistoryState {
    notes: NoteState[]
}

const initialState: NoteHistoryState = {
    notes: [],
};

export const NoteHistorySlice = createSlice(
    {
        name: 'noteHistory',
        initialState,
        reducers: {
            addNote(state, action: PayloadAction<NoteState>) {
                state.notes.push(action.payload);
            },
            removeNote(state, action: PayloadAction<number>) {
                state.notes = state.notes.filter(note => note.noteID !== action.payload);
            },
            updateNote(state, action: PayloadAction<NoteState>) {
                const index = state.notes.findIndex(note => note.noteID === action.payload.noteID);
                if (index !== -1) {
                    state.notes[index] = action.payload;
                }
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(getNoteHistoriesAsync.fulfilled, (state: NoteHistoryState, action: PayloadAction<NoteHistoryState>) => {
                    state.notes = action.payload.notes;
                })
                .addCase(addNoteAsync.fulfilled,(state:NoteHistoryState,action:PayloadAction<NoteState>)=>{
                    state.notes.push(action.payload)
                })
                .addCase(updateNoteAsync.fulfilled,(state:NoteHistoryState,action:PayloadAction<NoteState>)=>{
                    const index = state.notes.findIndex(note => note.noteID === action.payload.noteID);
                    if (index !== -1) {
                        state.notes[index] = action.payload;
                    }
                })
                .addCase(deleteNoteAsync.fulfilled,(state:NoteHistoryState,action:PayloadAction<number>)=>{
                    state.notes = state.notes.filter(note => note.noteID !== action.payload);
                })
        }
    }
)

const noteHistoryReducer=NoteHistorySlice.reducer;
export default noteHistoryReducer;
import {Link, Outlet, useFetcher, useLocation, useRouteLoaderData} from "react-router-dom";
import React, {useEffect} from "react";
import style from "./HomePage.module.scss"
import ChatHistory from "../ChatPage/ChatHistory/ChatHistory";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import NoteHistory from "@/component/NotePage/NoteHistory/NoteHistory";
import {logout} from "@/store/userStatus";
import Cookies from "js-cookie";
import {loginAndSetup} from "@/store/thunks/login";


const HomePage = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const userState = useSelector((state: RootState) => state.user);
    const [isHidden, setHidden] = React.useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        const token = Cookies.get('jwtToken') as string;
        if(token)
            dispatch(loginAndSetup({jwtToken: token}) as any);
    }, []);
    let historyComponent=null;
    if (currentPath.includes("/chat")) {
        historyComponent = <ChatHistory />;
    } else if (currentPath.includes("/note")) {
        historyComponent = <NoteHistory />;
    }
    return (
        <div className={style.homePage}>
            {isHidden&&<button onClick={() => setHidden(false)} className={style.showButton}>toggle</button>}
            {!isHidden&&<div className={style.sidebar}>
                <button onClick={()=>setHidden(true)}>hidden</button>
                <AuthStatus/>
                <ul>
                    <li>
                        <Link to="/">Public Page</Link>
                    </li>
                    {!userState.isLogged && <li>
                        <Link to="/login">Login</Link>
                    </li>}
                    {userState.isLogged && (
                        <div>
                            <li>
                                <Link to="/chat">Chat Page</Link>
                            </li>
                            <li>
                                <Link to={'/note'}>note</Link>
                            </li>
                            <li>
                                <Link to={'/command'}>command</Link>
                            </li>
                            <li>
                                <Link to={'/toolkit'}>toolkit</Link>
                            </li>
                        </div>
                    )}
                </ul>
                {historyComponent}
            </div>}
            <div className={style.mainContent}>
                <Outlet/>
            </div>
        </div>
    )
}

function AuthStatus() {
    // Hooks should be called at the top level before any early return
    const userState = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    // Early return should come after all hooks have been called
    if (userState.isLogged === false) {
        return <p>You are not logged in.</p>;
    }

    // Removed conditional rendering logic for signing out message since there's no 'isLoggingOut' state here
    return (
        <div>
            <h2>{userState.userName??"no user name"}</h2>
            <button onClick={() => dispatch(logout())}>
                Sign out
            </button>
        </div>
    );
}


export default HomePage

import {useDispatch} from "react-redux";

import {useEffect} from "react";

import {v4} from "uuid";
import {deleteMessageByUid, MessageState, updateMessage} from "@/store/messageSlice";
import {addMessageAsync} from "@/store/thunks/message";
import debugConfig from "@/debugConfig";
//@ts-ignore
import io from "socket.io-client";
import SocketManager from "@/model/sio";

const url =debugConfig.useLocalServer ? 'http://localhost:5678' : 'https://beforetime.life';
export default function useSocketListeners() {
    const dispatch = useDispatch()
    useEffect(() => {
        SocketManager.init(url);

        const chatResponseListener = (data: string) => {
            const ms: MessageState = {role: "assistant", content: data, uid: "lastData"};
            dispatch(updateMessage(ms));
        }
        const finishResponseListener = (data: string) => {
            dispatch(deleteMessageByUid('lastData'));
            const ms: MessageState = {role: "assistant", content: data, uid: v4()};
            dispatch(addMessageAsync(ms) as any);
        }

        SocketManager.on('open', function() {
            console.log('已连接到服务器');
        });
        SocketManager.on('message', (data:any) => {
            console.log('收到消息:', data);
        });

        SocketManager.on('close', (data:any) => {
            console.log('WebSocket连接已关闭');
        });
        SocketManager.on('chatResponse', chatResponseListener);
        SocketManager.on('finishResponse', finishResponseListener);

        return () => {
            SocketManager.close()
        };
    }, [])
}


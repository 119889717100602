import ask from "@/common/api/base";
import {AxiosRequestConfig} from "axios";
import {baseResponse} from "@/common/api/common";

export interface ICommand{
    commandID?:string,
    description:string,
    command:string,
    category: string,
}
interface ICommandResponse{
    commandID:string,
    message:string
}
export interface IGetCommandResponse{
    commands:ICommand[],
    message:string
}
export const CommandApi={

    async getCommand(query:string,config?: AxiosRequestConfig) {
        return await ask.get<IGetCommandResponse>(`/go/v0/Command?query=${query}`, config);
    },
    async addCommand(command: ICommand, config?: AxiosRequestConfig) {
        return await ask.post<ICommandResponse>('/go/v0/Command', command, config);
    },
    async deleteCommand(commandID: string, config?: AxiosRequestConfig) {
        return await ask.delete<baseResponse>(`/go/v0/Command/${commandID}`, config);
    },
    async updateCommand(commandID: string, command: ICommand, config?: AxiosRequestConfig) {
        return await ask.put<ICommandResponse>(`/go/v0/Command/${commandID}`, command, config);
    }
}
import React, {useState, useEffect, useRef} from 'react';
import styles from './InputModal.module.scss';

interface InputModalProps {
    isShowModal: boolean;
    title: string;
    onClose: () => void;
    onConfirm: (input: string) => void;
}

const InputModal: React.FC<InputModalProps> = ({ isShowModal, title, onClose, onConfirm }) => {
    const [input, setInput] = useState('');
    const inputRef = useRef<HTMLInputElement>(null); // 使用 useRef 获取 input 的引用

    useEffect(() => {
        setInput(''); // 每次弹窗显示时重置输入内容
        if (isShowModal) {
            // 当模态框显示时，聚焦到输入框
            inputRef.current?.focus();
        }
    }, [isShowModal]);

    useEffect(() => {
        // 处理键盘事件
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                onConfirm(input);
            } else if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isShowModal) {
            // 组件挂载时添加事件监听
            document.addEventListener('keydown', handleKeyDown);
        }

        // 组件卸载时移除事件监听
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [input, onConfirm, onClose, isShowModal]); // 依赖数组中包括了 input, onConfirm, onClose 以及 show

    if (!isShowModal) return null;

    const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((event.target as HTMLElement).id === "modal-backdrop") {
            onClose();
        }
    };

    return (
        <div id="modal-backdrop" onClick={handleBackdropClick} className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <h2 className={styles.title}>{title}</h2>
                <input
                    ref={inputRef} // 将 inputRef 附加到 input 元素
                    type="text"
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    className={styles.inputField}
                />
                <div className={styles.buttonContainer}>
                    <button onClick={() => onConfirm(input)} className={styles.confirmButton}>确认</button>
                    <button onClick={onClose} className={styles.cancelButton}>取消</button>
                </div>
            </div>
        </div>
    );
};


export default InputModal;
import {useState} from "react";
import style from "./toolkitPage.module.scss"
export default function ToolKitPage(){

    return (
        <div>
            <PruneChatGPT/>
        </div>
    )
}

const PruneChatGPT = () => {
    const [value, setValue] = useState("");

    function pruneChatGPT() {
        const regex = /\\\[((?:.|\s)*?)\\\]|\\\(((?:.|\s)*?)\\\)/g;
        const newValue = value.replace(regex, (_, match1, match2) => match1 || match2);
        setValue(newValue);
        console.log(newValue);
    }

    return (
        <div className={style.PruneChatGPT}>
            <h1>Prune ChatGPT Latex</h1>
            <textarea value={value} onChange={e => setValue(e.target.value)} />
            <button onClick={pruneChatGPT}>Prune</button>
        </div>
    );
}
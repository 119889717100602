import {createBrowserRouter, useLocation, useNavigate} from "react-router-dom";
import {RootState, store} from "./store/store";
import {Provider, useSelector} from "react-redux";
import React, {useEffect} from "react";
import ChatPage from "./component/ChatPage/ChatPage";
import HomePage from "./component/HomePage/HomePage";
import LoginStatus from "./component/Login/LoginStatus";
import NoteComponent from "@/component/NotePage/NoteComponent";
import CommandPage from "@/component/CommandPage/CommandPage";
import {IndexPage} from "@/component/indexPage/indexPage";
import ToolKitPage from "@/component/ToolkitPage/ToolKitPage";

const router = createBrowserRouter([
    {
        id: "root",
        path: "/",
        Component: Layout,
        children: [
            {
                index: true,
                Component: IndexPage,
            },
            {
                path: "login",
                // action: loginAction,
                // loader: loginLoader,
                Component: LoginStatus,
            },
            {
                path: "chat",
                // loader: protectedLoader,
                Component: ProtectedPage,
            },
            {
                path:"note",
                Component:NoteComponent   //todo react.lazy  and enhancers
            },
            {
                path:"command",
                Component:CommandPage
            }
            ,{
            path:'toolkit',
                Component:ToolKitPage
            }
        ],
    },
]);

export default router;

function Layout() {
    return (
        <div>
            <Provider store={store}>
                <HomePage/>
            </Provider>

        </div>
    );
}





function ProtectedPage() {
    const userState = useSelector((state: RootState) => state.user);
    const location = useLocation(); // Use useLocation here
    const navigate = useNavigate(); // Use useNavigate for navigation

    useEffect(() => {
        if (!userState.isLogged) {
            let params = new URLSearchParams();
            params.set("from", location.pathname); // Use location.pathname
            // Assuming you have a route to your login page, you can navigate to it
            // And possibly append 'from' search param if your login logic supports redirecting after login
            navigate(`/login?${params.toString()}`);
            return;
        }
    }, [userState.isLogged, location.pathname, navigate]);

    return <div style={{backgroundColor: '#f2f2f2'}}><ChatPage/></div>;
}

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {loginAndSetup} from "./thunks/login";
import debugConfig from "@/debugConfig";

// 定义 state 的类型
export interface UserState {
    userName: string;
    isLogged: boolean;
}

// 初始化 state
const initialState: UserState = {
    userName:debugConfig.skipLogin?"debug":'',
    isLogged:debugConfig.skipLogin
};


// 使用 createSlice 创建 slice
export const UserSlice = createSlice({
    name: 'userStatus',
    initialState,
    reducers: {
        setUserStatus: (state, action: PayloadAction<UserState>) => {
            state.userName = action.payload.userName;
            state.isLogged = action.payload.isLogged;
        },
        login: (state, action: PayloadAction<string>) => {
            state.userName = action.payload;
            state.isLogged = true;
        },
        logout: (state) => {
            state.userName = "";
            state.isLogged = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginAndSetup.fulfilled,(state,action)=>{
            state.userName=action.payload.userName;
            state.isLogged=true;
        })
    }
});

// 导出 actions
export const
    {
        logout
    } = UserSlice.actions;

// 导出 reducer
export default UserSlice.reducer;

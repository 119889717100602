import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {addConversationAsync, deleteConversationAsync} from "./thunks/conversation";
import {addMessageAsync} from "./thunks/message";
// 定义 state 的类型
export interface ConversationState {
    conversationID?: number,
    title?: string,
}

// 初始化 state
const initialState: ConversationState = {};


// 使用 createSlice 创建 slice
export const ConversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        setConversation: (state, action: PayloadAction<ConversationState>) => {
            state.conversationID = action.payload.conversationID;
            state.title = action.payload.title;
        },
        resetConversation: (state) => {
            state.conversationID = undefined;
            state.title = undefined;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addConversationAsync.fulfilled, (state, action) => {
            state.conversationID = action.payload.conversationID;
            state.title = action.payload.title;
        })
            .addCase(addMessageAsync.fulfilled, (state, action) => {
                if(!state.conversationID){
                    return
                }
            })
            .addCase(deleteConversationAsync.fulfilled, (state, action) => {
                if(action.payload===state.conversationID) {
                    state.conversationID = undefined;
                    state.title = undefined;
                }
            })
    }
});

// 导出 actions
export const
    {
        setConversation,
        resetConversation
    } = ConversationSlice.actions;

// 导出 reducer
export default ConversationSlice.reducer;

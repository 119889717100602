import axios from "axios";
import Cookies from "js-cookie";
import debugConfig from "../../debugConfig";

export function baseUrl(){
    if(debugConfig.isDebug)
        return "http://localhost:3100/api/";
    return "https://beforetime.life/api/"
}

const ask = axios.create({
    baseURL: baseUrl(),
    // 你可以在这里设置其他的全局配置
});

ask.interceptors.request.use(
    (config) => {
        // 获取存储的token
        const token = Cookies.get("jwtToken");
        if (token) {
            // 如果 token 存在，则将其添加到所有请求的 Headers 中
            config.headers["Authorization"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default ask
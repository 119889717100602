import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../store";
import Ask from "../../common/api/api";
import {v4} from "uuid";

interface IAddAIMessageAsync{
    uid: string,
    content: string,
    role: string,
}
interface IReturn {
    conversationID?: number,
    messageID?: number,
    content: string,
    uid: string,
    role: string,
}

export const addMessageAsync = createAsyncThunk(
    'message/add',
    async (messageData: IAddAIMessageAsync, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const conversationState = state.conversation;
        try {
            if (conversationState.conversationID) {
                const res = await Ask.addMessage(conversationState.conversationID,
                    messageData);
                const currentID = conversationState.conversationID;
                const data: IReturn = {
                    ...messageData,
                    conversationID: currentID, messageID: res.data.messageID,
                };
                return data; // 这个结果将会被 Fulfilled action payload 接收
            }
            const data:IReturn={...messageData};
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteMessageAsync=createAsyncThunk(
    'message/delete',
    async (messageID: number, thunkAPI) => {
        try {
            const res = await Ask.deleteMessage(messageID);
            return messageID;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
import React, {useState} from 'react';
import styles from './EditableDiv.module.scss';
import classNames from "classnames";

interface IProps {
    text: string;
    onTextChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    className?: string;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    children?: React.ReactNode;

}

const EditableDiv: React.FC<IProps> = ({ text, onTextChange, className, children, onBlur }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(text);

    const handleDoubleClick = () => {
        setEditValue(text);
        setIsEditing(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEditValue(e.target.value);
        onTextChange?.(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        setIsEditing(false);
        onBlur?.(e);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && !e.nativeEvent.isComposing) {
            e.preventDefault(); // 防止默认的换行行为
            handleBlur(e as unknown as React.FocusEvent<HTMLTextAreaElement>);
        } else if (e.key === 'Escape') {
            setEditValue(text); // 恢复原始文本
            setIsEditing(false);
        }
    };

    return isEditing ? (
        <textarea
            className={classNames(className, styles.editableTextarea)}
            value={editValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
        />
    ) : (
        <div className={classNames(className, styles.editableDiv)} onDoubleClick={handleDoubleClick}>
            {text}
            {children}
        </div>
    );
};
export default EditableDiv;
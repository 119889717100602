import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {useEffect} from "react";
import {setMessage} from "../../../store/messageSlice";
import {setConversation} from "../../../store/conversationSlice";
import {getAllConversationsAsync} from "../../../store/thunks/conversation";


const ChatHistory = () => {
    const conversations=useSelector((state: RootState) => state.chatHistory.conversations);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllConversationsAsync() as any);
    }, []);

    return (
        <div>
            <h1>Chat History</h1>
            <div onClick={()=>{
                console.log(conversations)
            }}>show some</div>
            {conversations?.map((aiConversation, index) => (
                <div key={index}
                     onClick={() => {
                            console.log(aiConversation);
                            dispatch(setMessage(aiConversation))
                            dispatch(setConversation(aiConversation))
                     }}>
                    {aiConversation.title}</div>
            ))}
        </div>
    );
}

export default ChatHistory;
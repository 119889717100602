import {ICommand} from "@/common/api";
import React from "react";
import styles from "@/component/CommandPage/CommandPage.module.scss";
import {IoCheckmarkOutline, IoCopyOutline, IoTrashOutline} from "react-icons/io5";
import EditableDiv from "@/component/CommandPage/EditableDiv";
import {useDispatch} from "react-redux";
import {setCommand} from "@/store/commandStore/commandSlice";
import {deleteCommandAsync, updateCommandAsync} from "@/store/commandStore/thunks/command";
import InputModal from "@/component/common/InputModal/InputModal";
import CustomModal from "@/component/common/CustomModal/CustomModal";
import EditableCommand from "@/component/CommandPage/EditableCommand";

interface IProps{
    command: ICommand
    index: number
}
const CommandComponent:React.FC<IProps> = props => {
    const {command,index} = props;
    const dispatch = useDispatch();

    return (
        <div key={props.index} className={styles.commandItem}>
            <IoTrashOutline className={styles.deleteIcon} onClick={handleDeleteCommands} />
            <EditableDiv className={styles.category} text={command.category} onBlur={
                e => {handleSavePart({...command, category: e.target.value})}
            }/>
            <EditableDiv className={styles.description} text={command.description} onBlur={
                e =>{handleSavePart({...command, description: e.target.value})}
            }/>
            <EditableCommand command={command.command} onBlur={
                e =>{handleSavePart({...command, command: e.target.value})}}
            />
        </div>
    )
    function handleDeleteCommands() {
        if(!command.commandID) return;
        dispatch(deleteCommandAsync(command.commandID) as any)
    }

    function handleSavePart(newCommand: ICommand) {
        if(!newCommand.commandID) return;
        if(newCommand.commandID !== command.commandID) return;
        if(shallowEqual(command,newCommand)) return;
        dispatch(updateCommandAsync(newCommand) as any)
    }

    function shallowEqual(obj1:any, obj2:any) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // 检查键的数量是否相等
        if (keys1.length !== keys2.length) {
            return false;
        }

        // 检查每个键的值是否相等
        for (const key of keys1) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }

        return true;
    }

}




export default CommandComponent
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getMessageBoardAsync} from "@/store/indexStore/indexThunks";

export interface IndexSlice{
    value:string
}
const initialState: IndexSlice = {
    value: ""
};

export const IndexSlice=createSlice(
    {
        name: "note",
        initialState,
        reducers: {
        },
        extraReducers:(builder)=>{
            builder
                .addCase(getMessageBoardAsync.fulfilled,(state,action:PayloadAction<string>)=>{
                    state.value=action.payload;
                })
        }
    }
)

const indexReducer=IndexSlice.reducer;
export default indexReducer;
import {createAsyncThunk} from "@reduxjs/toolkit";
import Ask, {ICommand} from "@/common/api";

export const searchCommandAsync = createAsyncThunk(
    'command/searchCommand',
    async (query: string, thunkAPI) => {
        try {
            const res = await Ask.getCommand(query);
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const updateCommandAsync = createAsyncThunk(
    'command/updateCommand',
    async (command: ICommand, thunkAPI) => {
        if(!command.commandID)
            return thunkAPI.rejectWithValue('Command ID is required');
        try {
            const res = await Ask.updateCommand(command.commandID, command);
            if(res.data.commandID === command.commandID) return command;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
        return thunkAPI.rejectWithValue('Update failed');
    }
)

export const deleteCommandAsync = createAsyncThunk(
    'command/deleteCommand',
    async (commandID: string, thunkAPI) => {
        try {
            const res = await Ask.deleteCommand(commandID);
            return commandID;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const addCommandAsync = createAsyncThunk(
    'command/addCommand',
    async (command: ICommand, thunkAPI) => {
        try {
            const res = await Ask.addCommand(command);
            return {...command, commandID: res.data.commandID};
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)
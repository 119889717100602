import ask from "@/common/api/base";
import {AxiosRequestConfig} from "axios";

export interface baseResponse {
    message: string;
}
interface ILoginResponse {
    token: string,
    username: string,
    message: string
}
export const BaseApi = {
    async ping() {
        return await ask.get('/go/v0/ping');
    },

    async pingJWT(config?: AxiosRequestConfig) {
        return await ask.get<baseResponse>('/go/v0/pingJWT', config);
    },

    async login(username: string, password: string) {
        return await ask.post<ILoginResponse>('/go/v0/login', {username, password});
    },

    async jwtLogin(config?: AxiosRequestConfig) {
        return await ask.post<ILoginResponse>('/go/v0/jwtLogin');
    },
}

export const login = BaseApi.login;
export const ping = BaseApi.ping;
export const jwtLogin = BaseApi.jwtLogin;
export const pingJWT = BaseApi.pingJWT;
interface DebugConfig {
    isDebug: boolean;
    skipLogin: boolean;
    fakeAuth:boolean;
    useLocalServer: boolean;
}

const debugConfig = new Proxy<DebugConfig>({
    isDebug: process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test',
    skipLogin: true,
    fakeAuth: false,
    useLocalServer: false,
}, {
    get(target, property, receiver) {
        // Ensure that 'property' is actually a key of DebugConfig
        if (typeof property !== "string" || !(property in target)) {
            return undefined; // Or some other handling as appropriate
        }

        // Note: This line only works if you're certain every property access
        // will be a string. If not, you would need further validation.
        const key = property as keyof DebugConfig;

        if (key !== 'isDebug' && !target.isDebug) {
            return false;
        }

        return Reflect.get(target, key, receiver);
    },
});



export default debugConfig;
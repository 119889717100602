// app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import messageReducer from './messageSlice'
import configReducer from './configSlice'
import chatHistoryReducer from "./chatHistorySlice";
import conversationReducer from "./conversationSlice";
import userReducer from "./userStatus"
import noteReducer from "@/store/noteStore/noteSlice";
import noteHistoryReducer from '@/store/noteStore/noteHistorySlice'
import commandReducer from "@/store/commandStore/commandSlice";
import indexReducer from "@/store/indexStore/indexSlice";

// 创建 store
export const store = configureStore({
    reducer: {
        message: messageReducer,
        config: configReducer,
        chatHistory: chatHistoryReducer,
        conversation: conversationReducer,
        user:userReducer,
        note:noteReducer,
        noteHistory:noteHistoryReducer,
        command:commandReducer,
        index:indexReducer,
    },
});

// 定义 RootState 类型
export type RootState = ReturnType<typeof store.getState>;

// 定义 AppDispatch 类型
export type AppDispatch = typeof store.dispatch;

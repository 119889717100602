import {createAsyncThunk} from "@reduxjs/toolkit";
import Ask, {IAIConversation} from "@/common/api";

export const addConversationAsync = createAsyncThunk(
    'conversation/add',
    async (conversationData: IAIConversation, thunkAPI) => {
        try {
            const res = await Ask.addAIConversation(conversationData);
            let data:IAIConversation = conversationData;
            data.conversationID= res.data.conversationID;
            if(!data.conversationID){
                return thunkAPI.rejectWithValue('conversationId is not returned');
            }
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const getAllConversationsAsync = createAsyncThunk(
    'conversations/fetchConversations',
    async (_, {rejectWithValue}) => {
        try {
            const response = await Ask.getAIConversations();
            console.log(response)
            return response.data.conversations;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteConversationAsync = createAsyncThunk(
    'conversation/delete',
    async (conversationID: number, thunkAPI) => {
        try {
            await Ask.deleteAIConversation(conversationID);
            return conversationID;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
import EditableDiv from "@/component/CommandPage/EditableDiv";
import styles from "@/component/CommandPage/CommandPage.module.scss";
import {IoCheckmarkOutline, IoCopyOutline} from "react-icons/io5";
import React, {useState} from "react";
import {ICommand} from "@/common/api";

interface IProps{
    command:string,
    onBlur?:(e: React.FocusEvent<HTMLTextAreaElement>)=>void
}
const EditableCommand:React.FC<IProps>= props=> {
    const {command,onBlur} =props
    const [isCopied, setIsCopied] = useState(false);

    // 复制到剪贴板的函数
    const copyToClipboard = (text:string) => {
        navigator.clipboard.writeText(text).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000); // 1秒后切换回复制图标
        });
    };

    return (
        <EditableDiv
            className={styles.command}
            text={command}
            onBlur={onBlur}
        >
            {isCopied ? (
                <IoCheckmarkOutline className={styles.icon} />
            ) : (
                <IoCopyOutline className={styles.icon} onClick={() => copyToClipboard(command)} />
            )}
        </EditableDiv>
    );
}

export default EditableCommand
import {AxiosRequestConfig} from "axios/index";
import ask from "@/common/api/base";

interface IMessageBoard{
    value:string
}
export const MessageBoardApi = {
    async getMessageBoard(config?: AxiosRequestConfig) {
        return await ask.get<IMessageBoard>(`/go/v0/MessageBoard`, config);
    },
    async updateMessageBoard(req: IMessageBoard, config?: AxiosRequestConfig) {
        return await ask.put(`/go/v0/MessageBoard`, req, config);
    }
}
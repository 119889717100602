import style from './ChatBar.module.scss'
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "@/store/store";
import InputModal from "@/component/common/InputModal/InputModal";
import {addConversationAsync, deleteConversationAsync} from "@/store/thunks/conversation";
import {clearMessage} from "@/store/messageSlice";
import {resetConversation} from "@/store/conversationSlice";
import {IAIConversation} from "@/common/api";


const ChatBar=()=>{
    const [show,setShow]=useState(false)
    const conversationState=useSelector((state:RootState)=>state.conversation);
    const messageState=useSelector((state:RootState)=>state.message);

    const dispatch=useDispatch()
    return (
        <div className={style.chatBar}>
            <InputModal isShowModal={show} title={"请输入对话名"} onClose={()=>setShow(false)}
                        onConfirm={addConversation}/>
            <button className={style.button} onClick={()=>{
                setShow(true)
            }}>Save</button>
            <h1 className={style.title}>{conversationState.title}</h1>
            <button className={style.button} onClick={()=>{
                if(conversationState.conversationID) {
                    dispatch(deleteConversationAsync(conversationState.conversationID) as any)
                    dispatch(clearMessage())
                    dispatch(resetConversation())
                }
            }}>Delete</button>

        </div>
    )

    function addConversation(input:string){
        const conversation:IAIConversation={title:input,AIMessages:messageState}

        dispatch(addConversationAsync(conversation) as any);
        setShow(false);
    }
}

export default ChatBar
import React, {useEffect, useState} from "react";
import styles from "./indexPage.module.scss";
import ask from "@/common/api/base";
import Ask from "@/common/api";
import {useSelector} from "react-redux";
import {RootState} from "@/store/store";
function MessageBoard() {
    const [value,setValue]=useState("");
    useEffect(()    => {
        getMessage();
    }, []);
    return (
        <div className={styles.messageBoard}>
            <h1>Message</h1>
            <button className={styles.refreshButton} onClick={getMessage}>刷新</button>
            <textarea className={styles.messageContainer} value={value} onChange={saveMessage}/>
        </div>);

    function getMessage() {
        const pValue=Ask.getMessageBoard();
        pValue.then((res)=>{
            setValue(res.data.value);
        });
    }
    function saveMessage(event: React.ChangeEvent<HTMLTextAreaElement>) {
        event.preventDefault();
        setValue(event.target.value);
        Ask.updateMessageBoard({value:event.target.value});
    }
}

export function IndexPage() {
    const userState = useSelector((state: RootState) => state.user);
    if (!userState.isLogged) {
        return <div>hello</div>
    }
    return <MessageBoard/>
}
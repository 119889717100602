import io from "socket.io-client";

let socket;
const SocketManager = {
    init: (url) => {
        socket = io(url);
        socket.on('open', function() {
            console.log('已连接到服务器');
        });
        socket.on('close', () => {
            console.log('WebSocket连接已关闭');
        });
    },
    emit: (event, data) => {
        if (socket) {
            socket.emit(event, data);
        }
    },
    on: (event, func) => {
        if (socket) {
            socket.on(event, func);
        }
    },
    off: (event, func) => {
        if (socket) {
            socket.off(event, func);
        }
    },
    close: () => {
        if (socket) {
            socket.close();
        }
    }
};

export default SocketManager;
